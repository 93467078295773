import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { createDynamicURL } from '../../utils/dynamicParams';
// ----------------------------------------------------------------------
const initialState = {
    status: 'idle',
    pricingGroupSeasons: [],
    pricingGroupSeason: null,
    meta: {
        currentPage: 0,
        nextPage: false,
        prevPage: false,
        totalPages: 0,
        totalRecords: 0
    },
    itemPaginationPage: 0,
};
// ----------------------------------------------------------------------
export const getPricingGroupSeasons = createAsyncThunk('pricingGroupSeasons/getPricingGroupSeasons', async ({ page = 0, rowsPerPage = 6, orderBy = 'createdAt', order = 'desc', pricingGroupId = '', seasonId = '', }) => {
    let data;
    const queryParams = {
        search: {
            pricingGroupId,
            'season.id': seasonId,
        },
        page: Number(page) + 1,
        perPage: rowsPerPage,
        orderBy,
        sortedBy: order,
    };
    const url = createDynamicURL('/pricingGroupSeasons', queryParams);
    try {
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data.data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getPricingGroupSeason = createAsyncThunk('pricingGroupSeasons/getPricingGroupSeason', async (id) => {
    let data;
    try {
        const response = await axios.get(`/pricingGroupSeasons/${id}`);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const createPricingGroupSeason = createAsyncThunk('pricingGroupSeasons/createPricingGroupSeason', async ({ Data, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.post(`/pricingGroupSeasons`, Data);
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getPricingGroupSeasons({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const editPricingGroupSeason = createAsyncThunk('pricingGroupSeasons/editPricingGroupSeason', async ({ id, Data, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.put(`/pricingGroupSeasons/${id}`, Data);
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getPricingGroupSeasons({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteManyPricingGroupSeasons = createAsyncThunk('pricingGroupSeasons/deleteManyPricingGroupSeason', async ({ getParams, ids }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/pricingGroupSeasons/many`, { data: [...ids] });
        data = await response.data;
        if (response.status === 200) {
            if (getParams)
                thunkAPI.dispatch(getPricingGroupSeasons({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
const slice = createSlice({
    name: 'pricingGroupSeason',
    initialState,
    reducers: {
        handleChangePaginationPage: (state, action) => {
            state.itemPaginationPage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPricingGroupSeasons.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getPricingGroupSeasons.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.pricingGroupSeasons = action.payload?.docs;
            state.meta = action.payload?.meta;
        })
            .addCase(getPricingGroupSeasons.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});
// Reducer
export const { handleChangePaginationPage } = slice.actions;
export const reducer = slice.reducer;
export default slice;
